.modal-navigation.issue {
  max-width: 500px !important;
}

.modal-navigation.right {
  margin-top: -15px;
  margin-left: 80px;
}
.modal-navigation.right-history {
  margin-top: -55px;
  margin-left: 35px;
}
.modal-navigation {
  position: absolute;
  z-index: 7000;
  width: auto;
  height: auto;
  max-width: 320px;
  margin-top: 10px;
  border: 1px solid rgb(216, 221, 230);
  border-radius: 0.25rem;
  padding: 0.25rem 0px;
  font-size: 14px;
  background: rgb(255, 255, 255);
  //   box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px 0px;
  filter: drop-shadow(rgba(116, 116, 116, 1) 1px 1px 40px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear 0s, visibility 0.1s linear 0s;
  color: #4b4b4b;
  margin-left: 3px;

  .navigation-header {
    margin: 15px;
    font-size: 20px;
    color: rgb(96 96 96);
    font-weight: 550;
  }
  .navigation-text {
    margin: 15px;
    font-size: 15px;
    height: auto;
    // animation: fadeIn 2s;
  }
  .navigation-text.issue-text {
    // animation: fadeIn 2s;
    // height: 220px !important;
  }
  // .navigation-text.subscribe-text {
  //   height: 150px !important;
  // }
  .nav-button {
    margin: 25px 15px 15px 15px;
    .primary {
      color: #ffffff;
      background: #1473e6;

      &:hover {
        background: #1067d0;
      }
    }
  }
}

.modal-navigation::before {
  left: 135px;
  right: auto;
  /* margin-right: -0.5rem; */
  /* box-sizing: content-box; */
  width: 30px;
  /* border-right: 1px solid; */
  border-top: 1px solid rgb(216, 221, 230);
  border-left: 1px solid rgb(216, 221, 230);
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  content: "";
  background-color: white;
  top: -15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px; */
}
.left::before {
  left: 15px;
  right: auto;
  /* margin-right: -0.5rem; */
  /* box-sizing: content-box; */
  width: 30px;
  /* border-right: 1px solid; */
  border-top: 1px solid rgb(216, 221, 230);
  border-left: 1px solid rgb(216, 221, 230);
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  content: "";
  background-color: white;
  top: -15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px; */
}

.right::before {
  left: auto;
  right: 40px;
  /* margin-right: -0.5rem; */
  /* box-sizing: content-box; */
  width: 30px;
  /* border-right: 1px solid; */
  border-top: 1px solid rgb(216, 221, 230);
  border-left: 1px solid rgb(216, 221, 230);
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  content: "";
  background-color: white;
  top: -15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px; */
}
.right-history::before {
  left: auto;
  right: 40px;
  /* margin-right: -0.5rem; */
  /* box-sizing: content-box; */
  width: 30px;
  /* border-right: 1px solid; */
  border-top: 1px solid rgb(216, 221, 230);
  border-left: 1px solid rgb(216, 221, 230);
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  content: "";
  background-color: white;
  top: -15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px; */
}
.help-col {
  float: right;
}
.info-col {
  float: right;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .help-col {
    .modal-navigation {
      margin-left: -70px;
    }
  }
  .right::before {
    right: 20px;
  }
  .centre::before {
    left: 210px;
  }
}

@media (max-width: 767px) {
  .modal-navigation.right {
    margin-top: 5px;
    // margin-right: -21px;
  }
  .modal-navigation.right.expand-collapse {
    margin-top: 15px;
    margin-right: -21px;
  }
  .modal-navigation.left {
    margin-top: 25px;
    margin-left: -25px;
  }
  .modal-navigation.left.subscribe {
    margin-top: 0px;
    margin-left: 0px;
  }
  .subscribe::before {
    left: 35px;
  }
  .right::before {
    right: 25px;
  }

  .modal-navigation.right-history {
    margin-top: 0px;
    margin-left: 0px;
  }
  .right-history::before {
    left: 40px;
    right: auto;
  }
}
@media (max-width: 1000px) and (min-width: 800px) {
  .modal-navigation.right {
    margin-top: 5px;
    right: 80px;
  }
  .modal-navigation.right.expand-collapse {
    right: 0px;
  }
  .modal-navigation.right.expand-collapse::before {
    right: 20px;
  }
  .modal-navigation.left {
    margin-top: 25px;
    margin-left: -25px;
  }
  .event-subs::before {
    left: auto;
    right: 100px;
  }
  .modal-navigation.right-history {
    margin-left: -0px;
  }
  .modal-navigation.right-history::before {
    right: 75px;
  }
}
@media (max-width: 800px) and (min-width: 768px) {
  .modal-navigation.right {
    margin-top: 5px;
    // margin-right: -21px;
  }
  .modal-navigation.right.expand-collapse {
    right: 0px;
  }
  .modal-navigation.right.expand-collapse::before {
    right: 20px;
  }
  .modal-navigation.left {
    margin-top: 25px;
    margin-left: -25px;
  }
  .manage-subs::before {
    left: auto;
    right: 60px;
  }
  .event-subs::before {
    left: auto;
    right: 100px;
  }
  .modal-navigation.right-history {
    margin-left: -25px;
  }
  .modal-navigation.right-history::before {
    right: 75px;
  }
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: normal;
  src: local("Adobe Clean Regular"),
    url("AdobeCleanRegular.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: italic;
  font-weight: normal;
  src: local("Adobe Clean Italic"), url("AdobeCleanIt.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 300;
  src: local("Adobe Clean Light"), url("AdobeCleanLight.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: italic;
  font-weight: 300;
  src: local("Adobe Clean Light Italic"),
    url("AdobeCleanLightIt.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 200;
  src: local("Adobe Clean SemiLight"),
    url("AdobeCleanSemiLight.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: bold;
  src: local("Adobe Clean Bold"), url("AdobeCleanBold.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: italic;
  font-weight: bold;
  src: local("Adobe Clean Bold Italic"),
    url("AdobeCleanBoldIt.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 800;
  src: local("Adobe Clean ExtraBold"),
    url("AdobeCleanExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: italic;
  font-weight: 800;
  src: local("Adobe Clean ExtraBold Italic"),
    url("AdobeCleanExtraBoldIt.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 900;
  src: local("Adobe Clean Black"), url("AdobeCleanBlack.woff") format("woff");
}

@font-face {
  font-family: "Adobe Clean";
  font-style: italic;
  font-weight: 900;
  src: local("Adobe Clean Black Italic"),
    url("AdobeCleanBlackIt.woff") format("woff");
}

.breadcrumbs {
  font-size: 13px;
  margin: 8px 0px;
  padding: 0;
  li:not(:first-child):before {
    content: "/";
    color: #6e6e6e;
    padding-right: 6px;
  }
  li {
    display: inline-block;
    padding-right: 6px;
  }

  a {
    color: #6e6e6e;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline !important;
  }
}

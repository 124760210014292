.status-stripe {
  background: $available-banner-color;
  color: #fff;
  padding: 10px 0;
  line-height: 20px;
  min-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;

  .icon-home {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url(../../image/home-icon.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 100%;
    position: relative;
    top: 4px;
    margin-right: 6px;
  }
  .go-to-overview {
    cursor: pointer;
    position: relative;
  }

  .status-icon::before {
    display: inline-block;
    margin-right: 8px;
    vertical-align: top;
  }
  &.pns-stripe {
    background: #d8e3f1;
    color: #444;
    margin-bottom: 30px;
    .text {
      display: inline-block;
      width: 85%;
    }
  }

  .banner-link {
    text-align: right;
    font-size: 13px;
    a {
      color: #fff;
      text-decoration: none;
      margin-right: 2px;
      font-size: 14px;
    }

    .hide-messages {
      border-left: 1px solid #fff;
      padding-left: 10px;
      display: none;
    }
  }
}

.status-stripe.pns-stripe {
  span.home-text {
    position: relative;
    top: -5px;
  }
  span.manage-text {
    position: relative;
    top: -5px;
  }
}
@media (max-width: 850px) and (min-width: 767px) {
  .status-stripe {
    &.pns-stripe {
      .text {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .status-stripe.pns-stripe {
    span.manage-text {
        position: relative;
        top: -5px;
    }
  }
  .go-to-overview {
    .home-text, .manage-text {
      span {
        display: none;
      }
    }
    
    i.icon-home {
      margin-right: 0px;
    }
  }
}

.subscribe-updates {
  display: block;
  font-size: 16px;
  min-height: 700px; //for higher resolution
  p {
    margin: 0;
  }
  .message {
    font-size: 14px;
    font-weight: bold;
  }
  .error-info {
    background: #fff4f4;
    margin-top: 20px;
    padding: 12px;
    color: #b31e1e;
    border-radius: 2px;
    a {
      text-decoration: none;
      color: #1473e6;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .event-id {
    font-size: 14px;
  }
  .label {
    font-size: 14px;
    color: #4b4b4b;
    margin-top: 10px;
  }
  .btn {
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 25px;
    text-decoration: none;
    line-height: 14px;

    &.loading {
      padding-right: 35px;
    }
  }
  .action-btn {
    color: #ffffff;
    background: #1473e6;
    border: 1px solid #1473e6;
  }

  .cancel-btn {
    border: 1px solid #8e8e8e;
    color: #8e8e8e;
    margin-right: 1em;
  }

  .container {
    max-width: 1060px;
    margin: 0 auto;

    .updates-info {
      max-width: 33em;
      margin: 0 auto;
      padding: 2em 1em;
    }

    .title {
      border-bottom: 1px solid #eaeaea;
      color: #2c2c2c;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .sub-title {
      font-size: 14px;
      margin-top: 5px;
    }

    .option {
      .label {
        font-size: 14px;
        color: #707070;
        margin-top: 0;
      }
      .info {
        font-size: 14px;
        color: #2c2c2c;
        width: 300px;
        font-weight: bold;
      }

      .info-icon {
        width: 15px;
        height: 15px;
        background-image: url(../../image/info-icons.svg);
        background-position: 0 -372px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: 2px;
        left: 2px;
      }
      .timezone-info {
        padding: 8px 10px;
        font-size: 12px;
        background: #fff3a4;
        margin: 6px 0;
        border-radius: 2px;
      }
    }
    .content {
      margin: 1.5em 0 2.5em;
      p {
        margin-bottom: 1em;
      }
      .options {
        .option {
          margin-bottom: 0.5em;
          span {
            display: inline-block;
          }
        }
      }
    }

    .actions {
      text-align: right;
    }
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-updates {
    min-height: 950px;
    .container {
      .content {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .subscribe-updates {
    min-height: 500px;
    .container {
      .content {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .unsubscribe-updates {
    .container {
      .content {
        display: block;
      }
    }
  }
}

.empt_my_events {
  margin: 18px 0;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .button {
    margin-top: 23px;

    .button-info {
      border-radius: 14px;
      padding: 8px 20px;
      font-size: 14px;
      line-height: 14px;
      margin-right: 6px;
      height: 32px;
      outline: 0;
      opacity: 1;
      background: #1473e6;
      color: #fff;
      border: 1px solid #1473e6;
    }
  }
}

#AdobeSecondaryNav {
    opacity: 0;
    transition: all 1s;
}

.ims-loaded {
    #AdobeSecondaryNav {
        opacity: 1;
        transition: all 1s;
    }
}

#AdobeSecondaryNav.Subnav-wrapper {
    position: relative !important;
    z-index: 0 !important;

    .Subnav-menu-wrapper {
        .Subnav-menu-item {
            .Subnav-menu-item {
                cursor: pointer;
            }
        }
    }

    a.Subnav-logo:hover {
        background-color: transparent !important;
    }

    a.Subnav-menu-label.tab-link.active {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .mobile-title {
        display: none;
    }

    .desktop-title {
        display: block;
    }

    &.has-mobileLayout {
        .mobile-title {
            display: block;
        }

        .desktop-title {
            display: none;
        }
    }
}
.generic-error-container {
    border: 2px solid #EC5B62;
    border-radius: 4px;
    padding: 15px;
    font-size: 14px;
    position: relative;
    margin: 15px 0 45px;
    i.event-icon{
        position: absolute;
        right: 10px;
        top:15px;
    }
    .error-title {
        font-weight: bold;
        margin: 0 0 10px;
    }

    .error-content {
        color: #747474;
    }
}
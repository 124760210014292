.unsubscribe-all {
  margin-bottom: 130px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
}

.buttons {
  float: right;
  margin-top: 60px;
  .btn {
    float: right;
    border-radius: 14px;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 14px;
    margin-right: 6px;
    height: 32px;
    outline: 0;
    opacity: 1;
    background: #fff;
    border: 1px solid #444;

    &.loading {
      padding-right: 35px;
    }

    &.primary {
      background: #1473e6;
      color: #fff;
      border: 1px solid #1473e6;
    }
    &:disabled {
      opacity: 0.8;
    }
  }
}

.error-info {
  background: #fff4f4;
  margin-top: 20px;
  padding: 12px;
  color: #b31e1e;
  border-radius: 2px;
  a {
    text-decoration: none;
    color: #1473e6;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: 1400px) {
  .unsubscription-container {
    .container {
      width: 1200px;
    }
  }
}

.product-tab-content {
  .product-main {
    margin-top: 20px;
  }
  .col-md-9.product-list-container {
    width: 100%;
  }
  .product-list-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 105%;
      border-left: 1px dashed #ccc;
      left: -33px;
    }
    .maintenance-text {
      font-size: 13px;
      .maintenance-link {
        cursor: pointer;
      }
    }
    .product-table-wrapper {
      margin-right: -240px;
      position: relative;
    }
    .product-table {
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      font: normal 13px Arial, sans-serif;
      width: 100%;

      thead th {
        border: none;
        padding: 10px;
        text-shadow: 1px 1px 1px #fff;
        white-space: nowrap;
        height: 36px;
        vertical-align: middle;
        text-align: center;
        font-size: 13px;

        &.no-content {
          color: #fff;
        }
      }
      .row-click {
        cursor: pointer;
      }
      tbody {
        tr {
          td {
            border-top: solid 1px #ddd;
            color: #333;
            padding: 10px;
            text-shadow: 1px 1px 1px #fff;
            white-space: nowrap;
            height: 60px;
            vertical-align: middle;
            text-align: center;

            .event-list-order {
              width: 440px;
              white-space: normal;
              ul,
              li {
                margin: 0;
                padding: 0;
              }
              li {
                padding: 4px 0 0;
                display: inline-block;
                margin-right: 4px;
                cursor: pointer;
              }
            }

            .event-icon {
              top: -2px;
              cursor: pointer;
              padding-left: 25px;

              &.major,
              &.minor,
              &.discovery {
                top: -4px;
              }
            }
            span.text {
              padding-right: 5px;
            }

            .de-highlight {
              opacity: 0.4;
            }
          }
        }
      }
      .sticky-col {
        left: 0;
        position: absolute;
        top: auto;
        width: 220px;
        text-align: left;

        & .link {
          cursor: pointer;
        }

        .valign {
          white-space: normal;
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          padding-top: 11px;
          &.id-3890 {
            padding-top: 4px;
          }
        }
      }
    }

    .product-table-scroller {
      margin-left: 220px;
      overflow-x: auto;
      overflow-y: visible;
      padding-bottom: 5px;
    }
    &.col-md-9 {
      min-height: 238px;
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        border-left: 1px dashed #ccc;
        left: -33px;
      }
    }

    .title {
      padding: 5px 0;
      position: relative;
      top: -13px;
      font-size: 15px;
    }

    .grid-offset-4 {
      position: relative;
      left: 7px;
    }
    .text-center {
      text-align: center;
    }

    .product-list {
      padding: 18px 8px;
      border-bottom: 1px solid #dfdfdf;
      cursor: pointer;

      .event-icon {
        font-size: 12px;
        margin-right: 10px;
        padding-left: 24px;
        display: inline-block;
        line-height: 20px;
      }
      .service-name {
        display: inline-block;
        width: 98%;
      }
      .de-highlight {
        opacity: 0.4;
      }

      &:first-child {
        border-top: 1px solid #dfdfdf;
      }
    }

    &.current-data {
      .product-table {
        thead th,
        tbody td {
          text-align: left;
          &.a-center {
            text-align: center !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .product-tab-content {
    .product-list-container {
      .product-table-wrapper {
        margin-right: -160px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .product-tab-content {
    .container {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media (max-width: 1000px) and (min-width: 767px) {
  .product-tab-content {
    .col-md-9.product-list-container {
      width: 100%;
    }
    .product-list-container {
      &::before {
        left: -10px !important;
      }
      .product-table-wrapper {
        margin-right: -120px;
      }
    }
  }
}

@media (max-width: 767px) {
  .product-tab-content {
    .col-md-9.product-list-container {
      width: 100%;
    }
    .product-list-container {
      .product-table-wrapper {
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 360px) {
  .product-tab-content {
    .product-list-container {
      .product-table {
        .sticky-col {
          width: 185px;
        }
      }

      .product-table-scroller {
        margin-left: 185px;
      }
    }
  }
}

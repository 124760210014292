
.tracker {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;

  & .path {
    stroke: #e0e0e0;
    stroke-linecap: round;
  }
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  
  & .path {
    stroke: #1472e6;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

.loader {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 50px auto;

    .tracker, .spinner {
      width: 50px;
      height: 50px;
    }
}

.btn-loader {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  top:0;
  bottom: 0;
  margin: auto;

  .tracker, .spinner {
    width: 20px;
    height: 20px;
  }
}

  
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  
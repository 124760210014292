.unsubscribe-btn {
  float: right;
  margin-top: 10px;
  .btn {
    border-radius: 14px;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 14px;
    margin-right: 6px;
    height: 32px;
    outline: 0;
    opacity: 1;
    background: #fff;
    border: 1px solid #444;

    &.loading {
      padding-right: 35px;
    }

    &.primary {
      background: #1473e6;
      color: #fff;
      border: 1px solid #1473e6;
    }
    &:disabled {
      opacity: 0.8;
    }
  }
}
.unsubscribe-product {
  margin-top: 32px;
  margin-bottom: 100px;
  a {
    color: #1473e6;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  .preference {
    margin-top: 15px;
    .info-icon {
      width: 15px;
      height: 15px;
      background-image: url(../../image/info-icons.svg);
      background-position: 0 -372px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      top: 2px;
      left: 2px;
    }
  }
  .lang-info {
    padding: 8px 10px;
    font-size: 12px;
    background: #fff3a4;
    margin: 6px 0;
    border-radius: 2px;
  }
}

@media (min-width: 1400px) {
  .unsubscribe-product-container {
    .container {
      width: 1200px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .unsubscribe-product {
    .grid-cols-4,
    .grid-cols-8 {
      width: 100%;
    }
    .grid-cols-8::before {
      display: none;
    }
  }
}

.banner-container {
  .status-stripe {
    background: $available-banner-color;
    color: #fff;
    padding: 10px 0;
    line-height: 20px;
    min-height: 20px;
    font-weight: 700;
    margin-bottom: 20px;

    .status-icon::before {
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
    }
    .icon.status-icon,
    .icon.status-icon-available {
      margin-top: -2px;
    }
    .status-icon-available {
      margin-right: 10px;
    }
    &.available,
    &.resolved,
    &.canceled {
      background: $available-banner-color;
    }
    &.major {
      background: $major-banner-color;
    }
    &.minor {
      background: $minor-banner-color;
    }
    &.maintenance {
      background: $maintenance-banner-color;
    }
    &.announcement {
      background: $message-banner-color;
    }
    &.discovery,
    &.potential {
      background: $discovery-banner-color;
    }
    &.pns-stripe {
      background: #d8e3f1;
      color: #444;
      margin-bottom: 30px;
    }

    .separator-list {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      width: calc(100% - 30px);

      .__react_component_tooltip {
        background-color: rgb(230, 227, 227);
        color: rgb(51, 51, 51);
        -webkit-box-shadow: 0 0 10px grey;
        box-shadow: 0 0 10px grey;
        opacity: 1 !important;
        display: block !important;

        li {
          &::before {
            border-left: 1px solid rgb(51, 51, 51) !important;
          }
        }
      }

      li {
        display: inline-block;
        font-size: 14px;
        &::before {
          content: "";
          display: inline-block;
          height: 15px;
          width: 6px;
          border-left: 1px solid #fff;
          margin: 3px 0 0 6px;
          vertical-align: top;
        }
      }
      li:first-child::before {
        width: 0px !important;
        margin: 3px 0 0 0 !important;
        border-left: none !important;
      }
    }

    .pns-link {
      .subscribe-icon {
        background-image: url(../../image/subscribe.png);
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-left: 6px;
        background-size: cover;
        position: relative;
        top: 3px;
      }
      .help-icon {
        background-image: url(../../image/menusmall.png);
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-left: 5px;
        background-size: cover;
        position: relative;
        top: 3px;
      }
      .text {
        margin-left: 1px;
      }
    }

    .banner-link {
      text-align: right;
      font-size: 13px;
      a {
        color: #fff;
        text-decoration: none;
        margin-right: 2px;
        font-size: 14px;
      }

      .hide-messages {
        border-left: 1px solid #fff;
        padding-left: 10px;
        display: none;
      }
    }
  }

  &.service-banner {
    .status-stripe {
      margin-bottom: 0;
    }
  }
}
@media (min-width: 1400px) {
  .banner-container.overview-banner {
    .container {
      width: 1200px;
    }
  }
}

@media (max-width: 767px) {
  .help-icon {
    background-image: url(../../image/menusmall.png);
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 0px !important;
    background-size: cover;
    position: relative;
    top: 3px;
  }
  .banner-container {
    .container,
    .container-sm {
      max-width: 100% !important;
      width: 100%;
    }
    .status-stripe {
      .separator-list {
        width: auto;
      }
      .container {
        padding: 0;
      }

      &.pns-stripe {
        .container {
          padding: 0 12px;
        }
      }
    }
    .pns-link {
      padding-right: 0px;
    }
    .desktop-pns {
      display: none;
    }

    &.service-banner {
      .status-stripe {
        .container {
          padding: 0 12px;
        }
      }
    }
  }
}

@media (max-width: 800px) and (min-width: 767px) {
  .pns-link {
    .text {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .status-stripe {
    .separator-list {
      width: 78%;
    }
  }
}

@media (max-width: 360px) {
  .status-stripe {
    .separator-list {
      li {
        font-size: 11px !important;
      }
    }
  }
}

/* The Modal (background) */

.modal-help {
  position: absolute;
  z-index: 7000;
  float: left;
  min-width: 180px;
  max-width: fit-content;
  margin-top: 10px;
  border: 1px solid rgb(216, 221, 230);
  border-radius: 0.25rem;
  padding: 0.25rem 0px;
  font-size: 14px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px 0px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear 0s, visibility 0.1s linear 0s;
  color: #4b4b4b;
  margin-left: 3px;

  .externallink {
    background-image: url(../../image/externaliconlinksmall.png);
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 3px;
    background-size: cover;
    position: relative;
    top: 3px;
  }

  .docsicon {
    background-image: url(../../image/userguidessmall.png);
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 1px;
    background-size: cover;
    position: relative;
    top: 5px;
  }

  li {
    list-style-type: none;
    padding: 10px;
    text-align: left;
  }
  li:hover {
    background-color: rgb(211, 211, 211);
  }

  .helpmenu {
    display: block;
    list-style-type: disc;
    margin-block-start: 10px;
    padding-inline-start: 0;
  }
}

.modal-help::before {
  left: 15px;
  right: auto;
  margin-right: -0.5rem;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  position: absolute;
  transform: rotate(45deg);
  content: "";
  background-color: white;
  top: -6px;
}
.menutext {
  padding-left: 10px;
}

.help-banner::before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 6px;
  border-left: 1px solid #fff;
  margin: 4px 0 0 0;
  vertical-align: top;
}

@media (max-width: 767px) {
  .modal-help {
    margin-left: -150px;
  }
  .modal-help::before {
    left: auto;
    right: 15px;
  }
}

@media (max-width: 800px) and (min-width: 767px) {
  .modal-help {
    margin-left: -145px;
  }
  .modal-help::before {
    left: auto;
    right: 15px;
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  .modal-help {
    margin-left: -110px;
  }
  .modal-help::before {
    left: auto;
    right: 45px;
  }
}
@media (max-width: 1360px) and (min-width: 1200px) {
  .modal-help {
    margin-left: -90px;
  }
  .modal-help::before {
    left: auto;
    right: 67px;
  }
}

.ShowHelpMenu{
display:block;
}
.HideHelpMenu{
display:None;
}
/* .options-container {
  display: flex;
  flex-wrap: wrap;
} */
.options-container {
    text-align: left;
}
.option-button {
    width: 200px;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: rgb(212 199 196 / 20%);
    text-align: center;
    color: #585858;
 &.active { width: 200px; background-color: #cb340f !important;
    color: white !important;}
    &:hover {
    background: #cb340f;
    color: white;
   
}

}

.option-inactive {
    width: 200px;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #a7a4a4;
    background: rgb(24 24 24 / 20%);
    text-align: center;
    color: #585858;
}


.change-buttons{
width: 200px;
 margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: rgb(212 199 196 / 20%);
    text-align: center;
    color: #585858;
  &.active { background-color: #cb340f !important;
    color: white !important;}
   &:hover {
    background: #cb340f;
    color: white;

}

     
}

.product-page {
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    z-index: 10000;

    &.active {
      display: block;
    }

    .wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    .text {
      color: #d8e3f1;
      display: inline-block;
      margin-left: 5px;
      &:before {
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        max-width: 7em;
        white-space: nowrap;
        color: #fff;
        animation: loading 8s linear;
      }
    }
  }
  .cfs-container {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    z-index: 10001;
    margin-bottom: 35px;
    width: 65%;

    &.active {
      display: block;
    }

    .close-cfs {
      font-size: 25px;
      position: absolute;
      right: 10px;
      top: 10px;
      color: #444;
      cursor: pointer;
      line-height: 10px;
    }

    .cfs-error {
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 45%;
      height: auto;
      color: red;
      font-style: italic;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 12px 30px 12px 15px;
      a {
        color: #228ecc;
      }
      .close-csf-error {
        position: absolute;
        font-size: 25px;
        right: 8px;
        top: 14px;
        color: #444;
        text-decoration: none;
        line-height: 10px;
      }

      &.active {
        display: block;
      }
    }
    #the-canvas,
    .the-canvas {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .product-name.title {
    font-weight: 800;
    display: inline-block;
    margin: 10px 0 0;
    padding-bottom: 16px;
    position: relative;
    top: 14px;
    font-size: 18px;
    color: #6e6e6e;
  }
  hr.title-hr {
    position: relative;
    top: -3px;
  }
  .event-filters {
    position: relative;
    bottom: 60px !important;
  }

  .fake-title {
    margin-bottom: 25px;
  }
  .event-container {
    position: relative;
    .status-filter {
      width: 150px;
      position: absolute;
      right: 12px;
      top: 6px;
      font-size: 14px;
    }
  }
  .product-list-container {
    min-height: 256px;
    position: relative;
    top: -12px;

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 10px);
      border-left: 1px dashed #ccc;
      left: -34px;
      top: 10px;
    }

    &.comm-container {
      min-height: 0px !important;
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(105%) !important;
        border-left: 1px dashed #ccc;
        left: -33px !important;
        top: 0px !important;
      }
    }

    .day-container {
      position: relative;
      h3 {
        font-size: 14px;
        padding: 6px 0;
        font-weight: bold;
        display: inline-block;
      }

      .status-msg {
        width: 100%;
        display: block;
        border: 1px solid #ccc;
        padding: 15px 30px;
        border-radius: 0;
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 6px;
        font-size: 15px;

        .event-icon {
          position: relative;
          top: 3px;
          right: 8px;
        }
      }

      &::before {
        content: "";
        width: 1px;
        height: 1px;
        border-radius: 100%;
        position: absolute;
        padding: 2px;
        left: -38px;
        background-color: #ccc;
        border: 2px solid #fff;
        box-shadow: 0 0 2px #ccc;
      }
      &::before {
        top: 10px;
      }
    }
  }
  .tabs {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
    border-bottom: 1px solid #ddd;
    list-style: none;
    li {
      display: inline-block;
      margin: 0px 40px 0 0;
      padding: 10px 0;
      position: relative;
      //border-bottom: 1px solid #ddd;
      //top: 3px;
      cursor: pointer;
      font-weight: 600;
      color: #8b8484;
      &.active {
        border-bottom: 2.5px solid #1592e5;
      }
    }
  }
  #filter-button {
    display: none;
  }
  #side-panel {
    display: block;
  }

  #filter-button {
    display: none;
  }

  .desktop-eventfilter {
    display: block;
  }
  .mobile-eventfilter {
    display: none;
  }
  .mobile-divider {
    display: none;
  }
}
@media (min-width: 1023px) {
  .product-page {
    #side-panel {
      display: block !important;
    }
    #event-filter {
      display: block !important;
    }
    #filter-button {
      display: none;
    }
  }
}

@media (max-width: 1023px) and (min-width: 767px) {
  .product-page {
    .cfs-container {
      width: 80%;
    }
    .container {
      max-width: 100%;
    }
    .event-container {
      width: 100% !important;
    }
    .col-md-3.side-panel {
      width: 100%;
    }

    .filter-panel .clear-all {
      right: 0;
    }

    .desktop-eventfilter {
      display: none;
    }
    .mobile-eventfilter {
      display: block;
      margin-bottom: -60px;
    }
    .product-list-container {
      top: 20px;
    }

    .event-filters ul.filter-container {
      float: left;
      margin-top: 60px;
      li.event-icon {
        margin-bottom: 5px;
        margin-right: 2px;
      }
    }
    .filter-panel {
      padding-bottom: 20px;
      .control-styles {
        width: 100%;
      }
    }
    .event-container {
      .status-filter {
        top: auto;
      }
    }

    #side-panel {
      display: none;
    }
    #filter-button {
      display: block;
      float: right;
      margin-top: -30px;

      button.btn {
        border-radius: 0px;
        border: none !important;
        background: none;
        padding: 0px 0px;
        margin-top: -50px;
      }
      .btn:focus {
        box-shadow: none;
      }
    }
    .mobile-divider {
      display: block;
    }

    .product-list-container .day-container::before {
      display: none;
    }
    .product-list-container::before {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .product-page {
    .cfs-container {
      width: 90%;
    }
    .desktop-eventfilter {
      display: none;
    }
    .mobile-eventfilter {
      display: block;
      margin-bottom: -60px;
    }
    .product-list-container {
      top: 20px;
    }

    .event-filters ul.filter-container {
      margin-top: 60px;
      float: left;
      li.event-icon {
        margin-bottom: 5px;
        margin-right: 2px;
      }
    }
    .filter-panel {
      padding-bottom: 20px;
      .control-styles {
        width: 100%;
      }
    }
    .event-container {
      .status-filter {
        top: auto;
      }
    }

    #side-panel {
      display: none;
    }
    #filter-button {
      display: block;
      float: right;
      margin-top: -30px;

      button.btn {
        border-radius: 0px;
        border: none !important;
        background: none;
        padding: 0px 0px;
        margin-top: -50px;
      }
      .btn:focus {
        box-shadow: none;
      }
    }
    .mobile-divider {
      display: block;
    }
  }
}
@media (max-width: 480px) {
  .product-page {
    .cfs-container {
      .close-cfs {
        font-size: 20px;
        right: 2px;
        top: 2px;
      }
    }
  }
}

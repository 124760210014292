.option-button {
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: rgb(212 199 196 / 20%);
    text-align: center;
    color: #585858;
 &.active { background-color: #cb340f !important;
    color: white !important;}
      &:hover {
    background: #cb340f;
    color: white;
}

}
.change-buttons{
 margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: #cb340f;
    text-align: center;
    color: white !important;
  &.active { background-color: #cb340f !important;
    color: white !important;}
   &:hover {
    background: #cb340f;
    color: white;

}

     
}

.global-nav {
  display: block;
}

body {
  position: relative;
}
.Gnav-active {
  z-index: 1000;
}

.Gnav-profile .Profile-dropdown {
  z-index: 11;
}

.footer {
  // position: relative;
  margin-top: 160px;
}

#disclaimer {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  a {
    font-size: 12px;
    color: #707070;
    padding: 20px;
    display: inline-block;
    text-decoration: none;
    max-width: 1400px;
    margin: 0 auto;
    &:hover {
      color: rgb(43, 154, 243);
    }
  }
  .disclaimer-footer-inner {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.modal-region {
  width: 80vw;
  color: #666666;
  z-index: 99;
  background: #fff;
}

.modal-region [class*="grid-cols-"]:first-child,
.modal-region [class*="grid-span-"]:first-child {
  padding-left: 0;
}

.modal-region [class*="grid-cols-"]:last-child,
.modal-region [class*="grid-span-"]:last-child {
  padding-right: 0;
}

.modal-region .modal-content .regions-header {
  margin-bottom: 0.313rem;
  font-weight: bold;
}

.modal-region .modal-content .regions-header h2 {
  margin: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-region .modal-content .regions-header p {
  color: #999;
  font-weight: 400;
  font-size: 1rem;
  margin: 0.75rem 0 0;
}

.modal-region .modal-content .regions-content {
  width: 100%;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  ul {
    float: left;
    width: 18%;
  }
}

.modal-region .modal-content .regions-content h5 {
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.modal-region .modal-content .regions-content ul + h5 {
  margin-top: 1.33em;
}

.modal-region .modal-content .regions-content ul li span {
  text-decoration: none;
  color: #2b9af3;
  cursor: pointer;
}

.modal-region .modal-content .regions-content ul li span:hover,
.modal-region .modal-content .regions-content ul li span:active,
.modal-region .modal-content .regions-content ul li span:focus {
  color: #33ccff;
}

.modal-region > label[for="modal-toggle"] {
  padding: 0;
  background: 0;
  left: auto;
  right: 10px;
  top: 5px;
  box-shadow: none;
  color: #ccc;
  font-size: 20px;
  z-index: 1;
}

.modal-region > label[for="modal-toggle"] .modal-toggle-visible:hover {
  cursor: pointer;
}

.modal-region > label[for="modal-toggle"] .modal-toggle-visible:before {
  content: "\f057";
}

.disclaimer-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;

  & .disclaimer-modal-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .disclaimer-modal-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    overflow: auto;
    background: #fff;
    border-radius: 5px;
    padding: 40px;
    max-width: 480px;
    box-sizing: border-box;
  }

  & .disclaimer-modal-title {
    color: #2c2c2c;
    font-weight: bold;
    font-size: 18px;
  }

  & .disclaimer-hr {
    border: 1px solid #e1e1e1;
    margin: 12px 0 16px 0;
    border-bottom: 0;
  }

  & .disclaimer-text {
    font-size: 14px;
    color: #4b4b4b;
    margin-bottom: 48px;
  }
  .disclaimer-btn {
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    padding: 0 1em;
    border-radius: 1em;
    text-decoration: none;
    height: 2em;
    line-height: 2em;
  }

  .cancel-disclaimer-btn {
    color: #ffffff;
    background: #1473e6;
    float: right;
  }
}

@media screen and (max-width: 66.675rem) {
  .modal-region .modal-content .regions-content {
    overflow: initial;
  }
}

.modal-canvas {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-frame {
  background: transparent;
}

.modal-frame > label[for="modal-toggle"] {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  top: -27px;
  right: -27px;
}

.modal-frame > label[for="modal-toggle"] .modal-toggle-visible:before,
.modal-frame > label[for="modal-toggle"]:hover .modal-toggle-visible:before {
  font-size: 1.375rem;
  color: #ccc;
  cursor: pointer;
}

.modal-frame > label[for="modal-toggle"]:hover .modal-toggle-visible:before {
  color: #fff;
}

/* The Modal (background) */

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  padding-top: 30px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: calc(100%);
  /* Full height */
  overflow: visible;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  margin: auto;
  border: none !important;
  width: 85%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  padding: 15px;
}

/* Add Animation */

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* Change Language modal */
#changeLanguageModal {
  .close {
    color: #666666;
    font-size: 28px;
    font-weight: bold;
    right: 20px;
    z-index: 99;
    position: absolute;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-body ul {
    list-style-type: none;
  }
}

i {
  font-style: normal;
  line-height: 1em;
}
@media (max-width: 1366px) and (min-width: 767px) {
  li#gnf_526_0 {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-region .modal-content .regions-content ul {
    width: 30%;
  }

  .modal-content {
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transform: translate(0, 0);
  }

  .Gnav-active {
    z-index: 1000;
  }
}

@media only screen and (min-width: 414px) and (max-width: 767px) and (orientation: landscape) {
  /** For the landscape mode height is made to 100% and not 100%-30px as to ensure
     *  all contents are within the space
     */
  .modal {
    height: 100%;
  }
  #changeLanguageModal {
    padding-top: 0;
    .modal-region .modal-content .regions-content ul {
      width: 20%;
    }

    .modal-content {
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      transform: translate(0, 0);
    }
  }
}

@media only screen and (max-width: 421px) {
  .modal-region .modal-content .regions-content ul {
    width: 50%;
  }
}

.proactive-notifications-con {
  display: block;

  .status-stripe {
    background: #fff;
    color: #fff;
    padding: 10px 0;
    line-height: 20px;
    min-height: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .backdrop {
    background-color: #44444482;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .slack-confirm {
    background-color: #fff;
    border-radius: 4px;
    width: 50%;
    max-width: 450px;
    min-width: 310px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50px;
    position: absolute;
    z-index: 100;
    padding: 15px;

    .slack-title {
      font-weight: 300;
      font-size: 20px;
      margin: -15px -15px 28px;
      padding: 20px;
      border-bottom: 1px solid #ebebeb;
    }

    .buttons {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .status-stripe.pns-stripe {
    background: #d8e3f1;
    color: #444;
    margin-bottom: 30px;
  }

  .status-stripe.pns-stripe {
    .grid-container span {
      font-size: 13px;
      color: #6f6f6f;
      font-weight: 400;
    }
  }

  .mandatory_field {
    color: #b31e1e;
  }

  .pns-wizard {
    margin: 0 0 30px;

    .cloud-products {
      width: 20%;

      .product-list {
        display: none;
        padding-left: 2px;
        margin-left: 0 !important;
        margin-right: 0 !important;

        .entitlement-container {
          margin: 8px 0;
          padding: 3px 14px 1px 0;
          position: relative;
          margin-top: 20px;

          &::after {
            content: "";
            position: absolute;
            width: calc(100% - 4px);
            height: 100%;
            top: -6px;
            left: -4px;
            box-shadow: 0 0 3px #ccc;
            z-index: -1;
          }
        }

        .has-offering {
          position: relative;
          padding-left: 20px;

          .expand::before {
            content: "+";
            width: 14px;
            height: 14px;
            display: inline-block;
            position: absolute;
            left: 1px;
            top: 0px;
            padding: 3px;
            line-height: 4px;
            background: #707070;
            color: #fff;
            box-shadow: 0px 0px 2px #c5c5c5;
            border-radius: 1px;
            outline: 1px solid rgba(255, 255, 255, 0.4);
            font-size: 13px;
            text-align: center;
          }

          .checkbox-container {
            padding-left: 0;

            .checkmark {
              display: none;
            }
          }

          .service-offerings {
            display: none;

            ul {
              list-style: none;
              padding: 0 0 1px 0px;
              margin: 0;
            }
          }

          &.active {
            .service-offerings {
              display: block;
            }

            .checkbox-container {
              padding-left: 20px;

              .checkmark {
                display: block;
              }
            }

            .expand::before {
              content: "–";
            }
          }

          &.partial {
            .service-offer-label.checkbox-container input:checked~.checkmark {
              background-color: #fff;
              border: 1px solid #707070;
            }

            .service-offer-label.checkbox-container .checkmark::after {
              left: 3px;
              top: 3px;
              width: 7px;
              height: 6px;
              border: solid #fff;
              border-width: 0;
              -webkit-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              transform: rotate(0deg);
              background: #1473e6;
              box-shadow: 0 1px 0px #2863ab6b inset;
            }
          }
        }
      }
    }

    .buttons {
      float: right;
      margin-top: 30px;

      .btn {
        border-radius: 14px;
        border: none;
        padding: 8px 20px;
        font-size: 14px;
        line-height: 14px;
        margin-right: 6px;
        height: 32px;
        outline: none;
        opacity: 1;

        &.loading {
          padding-right: 35px;
        }

        &.secondary {
          background: #fff;
          border: 1px solid #444;
        }

        &.primary {
          background: #1473e6;
          color: #fff;
          border: 1px solid #1473e6;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          outline: none;
          opacity: 0.9;
        }

        &:active {
          opacity: 1;
        }

        &:disabled,
        &[disabled] {
          opacity: 0.6;
        }
      }
    }

    .select-product-message {
      display: block;
      margin: 75px 0;
      text-align: center;
      color: #bdbdbd;

      &.hide {
        display: none;
      }
    }

    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 14px;
      cursor: pointer;
      font-size: 13px;
      line-height: 13px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.title {
        font-weight: bold;
        border-bottom: 2px solid #666;
        padding-bottom: 6px;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 12px;
        position: relative;
        padding-left: 23px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .__react_component_tooltip {
          display: none !important;
        }

        .checkmark {
          display: none !important;
        }

        &::after {
          content: "+";
          width: 14px;
          height: 14px;
          display: inline-block;
          position: absolute;
          left: 5px;
          top: 1px;
          padding: 3px;
          line-height: 4px;
          background: #707070;
          color: #fff;
          box-shadow: -2px -2px 0px #707070;
          border-radius: 1px;
          text-align: center;
          outline: 1px solid rgba(255, 255, 255, 0.4);
        }

        &.active {
          &::after {
            content: "–";
            text-align: center;
          }

          &+.product-list {
            display: block;
          }
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &:hover input~.checkmark {
        background-color: #ccc;
      }

      input:checked~.checkmark {
        background-color: #1473e6;
        border: 1px solid #1473e6;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 2px;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }

        &::after {
          left: 4px;
          top: 1px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      input:checked~.checkmark:after {
        display: block;
      }

      &.partial {
        .checkmark {
          &::after {
            width: 5px;
            height: 0px;
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
            left: 3px;
            top: 5px;
          }
        }
      }
    }

    .steps-progress {
      width: 80%;
      margin: 15px auto 50px;
      height: 2px;
    }

    .progressbar {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        color: #7d7d7d;

        &:before {
          width: 5px;
          height: 5px;
          content: "";
          line-height: 5px;
          display: block;
          text-align: center;
          margin: 0 auto -12px auto;
          border-radius: 50%;
          background-color: #b7b7b7;
          position: relative;
          top: 13px;
        }

        &:after {
          width: 100%;
          height: 2px;
          content: "";
          position: absolute;
          background-color: #b7b7b7;
          top: 15px;
          left: -50%;
          z-index: -1;
        }

        &:first-child:after {
          content: none;
        }

        &.active {
          color: #444;

          &:before {
            background-color: #444;
          }

          &:after {
            background-color: #444;
          }
        }
      }
    }

    .global-event,
    .custom-event {
      margin-bottom: 15px;
      display: block;

      span {
        display: inline-block;
        padding-left: 8px;
        position: relative;
        top: -2px;
      }

      &.hide {
        display: none;
      }
    }

    .region-global-container,
    .region-custom-container {
      display: none;

      &.active {
        display: block;
      }
    }

    .region-container {

      .wrapper,
      &.custom {
        border: 1px solid #b7b7b7;
        border-radius: 6px;
      }

      .wrapper {
        margin-bottom: 22px;
      }

      &.custom {
        margin-bottom: 20px;

        .product-offering {
          border-bottom: 1px solid #e2e2e2;
          padding: 12px 16px 12px 14px;
        }
      }

      .product {
        background: #f3f3f3;
        padding: 11px 16px 11px 14px;
        font-weight: bold;
        border-radius: 6px 6px 0 0;

        .delete-icon {
          width: 12.5px;
          height: 12.5px;
          display: inline-block;
          background-image: url(../../image/delete.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          margin-top: 3px;
        }
      }

      .region,
      .event-type {
        clear: both;
        font-size: 13px;
        margin: 12px 0;
        padding-left: 14px;

        &::after {
          content: "";
          clear: both;
          display: table;
        }

        .title {
          font-weight: bold;
        }

        ul {
          margin: 15px 0;
          padding: 0;
          clear: both;
          list-style: none;

          li {
            padding: 0;
            margin: 0 20px 10px 0;
            float: left;
            width: 20%;
          }
        }
      }

      .region.ru,
      .event-type.ru {
        ul {
          li {
            width: 24%;
          }
        }
      }

      .region.pl,
      .event-type.pl {
        ul {
          li {
            width: 21%;
          }
        }
      }

      .region {
        margin-top: 14px;

        .environment-group {
          margin: 10px 0;

          .groups {
            display: inline-block;
            margin-right: 20px;

            .environment-group-link {
              color: #1473e6;
              text-decoration: none;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }

              &.active {
                color: #444;
              }
            }
          }

          .environment-group-title {
            margin: 18px 0;
            font-size: 13px;
            font-weight: bold;
          }

          .environment-region-list {
            display: none;

            &.active {
              display: block;
            }
          }
        }
      }

      .event-type {
        margin-bottom: 8px;
      }
    }

    .selected-products {
      margin-top: 20px;
      margin-bottom: 30px;
      margin-right: 10px;
      margin-left: 10px;

      .row {
        padding: 16px;
        border: 1px solid #9a9a9a;
        border-radius: 12px;
        margin-bottom: 12px;

        &::after {
          display: table;
          clear: both;
          content: "";
        }

        .product-name {
          font-weight: bold;
        }

        span.cloud-name {
          font-size: 13px;
          color: #868585;
        }

        .col-md-8 {
          position: relative;
          color: #828282;
          font-size: 13px;

          &::before {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            border-left: 1px solid #868585;
            left: -20px;
          }
        }
      }
    }

    .pns-wizard-item {
      .region-info {
        padding: 0 0 15px 0;
      }

      .entitlement-message,
      .select-info {
        padding: 10px 14px;
        background: #fff3a4;
        margin-bottom: 20px;
        font-size: 13px;
        border-radius: 2px;
      }

      .entitlement-message {
        display: none;

        &.show {
          display: block;
        }
      }

      &.product,
      &.region {
        display: none;

        &.active {
          display: block;
        }
      }

      &.confirm {
        h3 {
          margin-bottom: 10px;
          clear: both;
          font-size: 16px;
          font-weight: bold;
        }

        span {
          a {
            color: #1473e6;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .error-msg {
          background: #ffe1e1;
          display: none;
          padding: 12px;
          color: #b31e1e;
          border-radius: 2px;

          &.show {
            display: block;
          }

          a {
            text-decoration: none;
            color: #1473e6;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .preference {
        .info-icon {
          width: 15px;
          height: 15px;
          background-image: url("../../image/icons.svg");
          background-position: 0 -372px;
          cursor: pointer;
          display: inline-block;
          position: relative;
          top: 2px;
          left: 2px;
        }

        margin-top: 10px;

        .lang-info,
        .slack-info,
        .timezone-info {
          padding: 8px 10px;
          font-size: 12px;
          background: #fff3a4;
          margin: 6px 0;
          border-radius: 2px;
          display: none;

          &.active {
            display: block;
          }

          a {
            color: #1473e6;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    .pns-wizard .checkbox-container.title {
      .__react_component_tooltip {
        display: block !important;
      }
      .__react_component_tooltip::before {
        z-index: -10 !important;
      }
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .pns-wizard {
      padding: 0 0 0 8px;

      .cloud-products .product-list {
        padding-right: 4px;
      }

      .checkbox-container {
        font-size: 11px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .pns-wizard {
      .pns-wizard-item {

        &.confirm,
        .preference {
          span {
            font-size: 13px;
          }
        }

        .row {
          display: block;
        }

        h3 {
          font-size: 1.17em;
          font-weight: bold;
        }
      }

      .cloud-products {
        width: 100%;
        padding: 0 15px !important;
        box-sizing: border-box;

        .checkbox-container {
          margin-bottom: 14px;
        }

        .product-list {
          margin-top: 18px;
        }
      }

      .checkbox-container {
        font-size: 13px;

        &.title {
          padding-bottom: 20px;
          padding-top: 8px;
          margin-right: 0;
          margin-bottom: 10px;

          &::after {
            top: 9px;
          }
        }
      }

      .select-product-message {
        display: none !important;
      }

      .region-container {

        .region,
        .event-type {
          ul li {
            width: 28%;
          }
        }
      }

      .selected-products {
        .row {
          .col-md-4 {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #9a9a9a;
          }

          .col-md-8 {
            .selected-events {
              margin-top: 4px;
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

html[lang="ja-jp"],
html[lang="zh-cn"],
html[lang="ko-kr"],
html[lang="zh-tw"] {
  .proactive-notifications-con .pns-wizard .checkbox-container.title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .steps-progress {
    width: 100% !important;
    margin: 0 auto 65px !important;
  }

  .proactive-notifications-con .pns-wizard .progressbar li:before {
    margin: 0 auto 18px auto;
  }

  .proactive-notifications-con .pns-wizard .buttons .btn {
    padding: 8px 18px;
  }

  html:not([lang="en"]) {
    .pns-wizard {

      .custom-event,
      .global-event {
        clear: both;

        span {
          width: 92%;
        }
      }

      .global-event {
        padding-top: 20px;
      }

      .pns-wizard-item {

        &.product,
        &.region,
        &.confirm {
          margin-top: 60px;
        }
      }
    }
  }

  .product-content .action-btn {
    float: none;
  }

  .region-container {

    .region,
    .event-type {
      ul li {
        width: 40% !important;
      }
    }
  }
}

@media (min-width: 1400px) {
  .proactive-notifications-con {
    .container {
      width: 1200px;
    }
  }
}

@media (max-width: 1200px) {
  .proactive-notifications-con {

    .container,
    .container-sm,
    .container-md {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .proactive-notifications-con {
    .steps-progress {
      width: 100% !important;
      margin: 0 auto 85px !important;
    }

    .pns-wizard-item {
      .buttons {
        margin-top: 0;
        width: 100%;

        .btn {
          margin-top: 15px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {

  .pns-wizard .custom-event span,
  .pns-wizard .global-event span {
    font-size: 13px;
  }
}

@media (max-width: 1000px) and (min-width: 767px) {
  .proactive-notifications-con {
    .cloud-products {
      padding-right: 0px;
    }
  }
}
.active,
.collapsible:hover {
  opacity: 1;
}

.pull-right {
  float: right;
}

.vertical {
  border-left: 1px solid #9a9a9a;
}

.delete-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(../../image/delete.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  margin: auto;
}

.relative {
  position: relative;
}

button.btn {
  border-radius: 20px;
  border: 1px solid #333;
  background: #fff;
  padding: 5px 15px;
  position: relative;
  font-size: 13px;

  &:hover {
    border: 1px solid #444444 !important;
  }

  &:focus {
    outline: none;
  }
}

.title {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  top: -5px;
}

.subscription-container {
  min-height: 400px;
}

.subscription-tab {
  .lang-info {
    padding: 8px 10px;
    font-size: 12px;
    background: #fff3a4;
    margin: 6px 0;
    border-radius: 2px;
  }

  button.unsubscription {
    margin-right: 10px;
  }

  .tabs {
    display: block;
    margin: 0 0 20px 0;
    padding: 0;
    border-bottom: 3px solid #ddd;
    list-style: none;

    li {
      display: inline-block;
      margin: 0px 12px 0 0;
      padding: 10px 0;
      position: relative;
      border-bottom: 3px solid #ddd;
      top: 3px;
      cursor: pointer;
      font-weight: bold;

      &.active {
        border-bottom: 3px solid #444;
      }
    }
  }

  .subscription-tab-content {
    .over-spin {
      position: absolute;
      width: 50px;
      margin: auto;
      left: 0;
      right: 0;
      z-index: 10;
    }

    &.active {
      display: block;
    }

    .no-subscription {
      margin-bottom: 30px;
      margin-top: 20px;

      p {
        margin-top: 24px;
      }
    }

    .status-message-list {
      margin: 0;
      padding: 0;
    }

    .preference {
      margin-top: 20px;
      margin-bottom: 20px;


      i.prof-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background-position: center;
        background-size: cover;
        position: relative;
        top: 5px;
        margin-right: 5px;

        &.email-icon {
          background-image: url(../../image/email.svg);
          background-size: 92% 92%;
        }

        &.lang-icon {
          background-image: url(../../image/locale.svg);
        }

        &.timezone-icon {
          background-image: url(../../image/timezone.svg);
        }

        &.slack-icon {
          background-image: url(../../image/slack.svg);
          background-size: 40px;
        }
      }

      .info-icon {
        width: 15px;
        height: 15px;
        background-image: url(../../image/info-icons.svg);
        background-position: 0 -372px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: 2px;
        left: 2px;
      }


      .lang-info,
      .slack-info,
      .timezone-info {
        padding: 8px 10px;
        font-size: 12px;
        background: #fff3a4;
        margin: 6px 0;
        border-radius: 2px;

        &.active {
          display: block;
        }

        a {
          color: #1473e6;
          text-decoration: none;
        }
      }
    }

    &.event-content {
      .status-message {
        border: 1px solid discovery-color;
        border-radius: 0.25rem;
        padding: 0.8rem 1.2rem 1rem;
        margin-bottom: 0.8rem;

        .service-name {
          font-size: 16px;
          padding-bottom: 3px;
        }

        .banner-services {
          margin: 0;

          ul {
            padding: 0;
            margin: 0;
          }
        }

        .login-link {
          color: #228ecc;
          cursor: pointer;
        }

        .impacted-services {
          display: inline-block;
        }

        .impacted-services span:after {
          content: ", ";
        }

        .impacted-services span:last-child:after {
          content: " ";
        }

        &.status-major {
          border-color: discovery-color;
        }

        &.status-minor {
          border-color: discovery-color;
        }

        &.status-discovery,
        &.status-potential {
          border-color: discovery-color;
        }

        .link-event {
          text-align: right;

          a {
            text-align: center;
            color: #4b4b4b;
            text-decoration: none;
            padding: 6px 10px;
            border: 1px solid #a6a6a6;
            border-radius: 28px;
            margin-top: 5px;
            display: inline-block;
            cursor: pointer;

            &:hover {
              text-decoration: none;
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

.product-list {
  margin-top: 10px !important;
  margin-bottom: 10px !important;

  .row {
    padding: 16px;
    border: 1px solid #9a9a9a;
    border-radius: 12px;
    margin-bottom: 12px;

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .product-name {
      font-weight: bold;
    }

    span.cloud-name {
      font-size: 13px;
      color: #868585;
    }

    .grid-cols-7 {
      position: relative;
      color: #828282;
      font-size: 13px;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        border-left: 1px solid #868585;
        left: -20px;
      }
    }

    .grid-cols-1 {
      position: static;

      .delete-icon {
        width: 12px;
        height: 12px;
        display: inline-block;
        //background-image: url(../img/delete.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 25px;
        margin: auto;
      }
    }
  }
}

.mobile-list {
  .collapsible .vertical-center::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    border-right: none;
    left: 5px;
    top: 0;
  }
}

@media (min-width: 1400px) {
  .manage-subscription-container {
    .container {
      width: 1200px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .subscription-container {
    .collapsible .vertical-center::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      border-right: none;
      left: 5px;
      top: 0;
    }

    .pull-right {
      float: none;
    }
  }
}

@media only screen and (max-width: 530px) {
  .subscription-container {
    .btn.unsubscription {
      margin-bottom: 10px;
    }

    .btn.edit-subscription {
      margin-bottom: 10px;
    }
  }
}

// @media only screen and (max-width: 425px) {
//   .subscription-container {
//     .subscription-tab .tabs li {
//       font-size: 9px;
//     }
//     .btn.unsubscription {
//       font-size: 9px;
//     }
//     .btn.edit-subscription {
//       font-size: 9px;
//     }
//   }
// }
// @media only screen and (max-width: 358px) {
//   .subscription-container {
//     .subscription-tab .tabs li {
//       font-size: 8px;
//     }
//     .btn.unsubscription {
//       font-size: 8px;
//     }
//     .btn.edit-subscription {
//       font-size: 8px;
//     }
//   }
// }
.product-list {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;

  .horizontal {
    border-top: 1px solid #9a9a9a;
  }

  .row {
    padding: 16px;
    border: 1px solid #9a9a9a;
    border-radius: 12px;
    margin-bottom: 12px;

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .product-name {
      font-weight: bold;
    }
    span.cloud-name {
      font-size: 13px;
      color: #868585;
    }
    .col-md-7 {
      padding-left: 12px;
      color: #828282;
      border-left: 1px solid #9a9a9a;
    }
    .horizontal {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .product-list {
    .row {
      .col-md-7 {
        border-left: 0px;
      }
      .horizontal {
        display: block;
      }
    }
    .delete-icon {
      margin-top: 30px;
      margin-right: 10px;
    }
  }
}

.slack-landing {
    .slack-banner {
        background-color: #F5F5F5;
    }

    #disclaimer {
        display: none;
    }

    .landing-screen-row::after {
        content: "";
        clear: both;
        display: table;
    }

    .add-to-slack {
        background-color: #FFFFFF;
        border: 1px solid #C6CACD;
        border-radius: 5px;
        height: 37px;
        outline: none;

        .add-to-slack-text:before {
            content: "";
            width: 25px;
            background-image: url(../../image/slackLogo.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-right: 4px;
            height: 22px;
            margin-top: -1px;
        }

        .add-to-slack-text {
            color: #0a0a0a;
            font-size: 15px;
            display: flex;
            justify-content: space-around;
            margin-right: 2px;
        }
    }

    .landing-screen-row {
        max-width: 1075px;
        margin-left: auto;
        margin-right: auto;

        .landing-screen-image-column {
            width: 50%;
            float: left;

            .landing-screen-image {
                background-image: url(../../image/landing.png);
                height: 300px;
                width: 100%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

            }
        }

        .landing-screen-text-column {
            width: 50%;
            float: left;
            padding: 4%;
            padding-left: 3%;
            padding-top: 7%;
            padding-right: 6%;

            .stay-up-to-date {
                margin: 0px;
                height: 27px;
                display: inline-block;
                letter-spacing: 0px;
                color: #736D78;
                opacity: 1;
                font-size: 15.5px;
            }

            h1 {
                margin: 0px;
                font-size: 34px;
                color: #1D1C1D;
                width: 280px;
                height: 50px;
            }

            a {
                color: #2680EB;
                text-decoration: none;
                font-size: 15.5px;
            }

            .about-status-adobe {
                margin: 0px;
                letter-spacing: 0px;
                color: #736D78;
                opacity: 1;
                font-size: 15.5px;
                padding-bottom: 20px;

                div {
                    margin-top: 15px;

                    h3 {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .slack-icon {
                        display: inline-block;
                        padding-left: 25px;
                        position: relative;

                        &::before {
                            content: '';
                            background-image: url(../../image/slack.svg);
                            width: 30px;
                            height: 30px;
                            display: inline-block;
                            position: absolute;
                            left: -3px;
                            top: -8px;
                        }
                    }
                }
            }

            .check-it {
                color: #1D1C1D;
                width: 139px;
                height: 27px;
                font-size: 18px;
            }
        }
    }

    .on-boarding-header {
        text-align: center;
        color: #1D1C1D;
        font-size: 34px;
        margin-top: 62px;
        margin-bottom: 40px;
    }

    .landing-onboading-steps-screen-row::after {
        content: "";
        clear: both;
        display: table;
    }

    .landing-onboading-steps-screen-row {
        padding: 0 4px;
        margin-bottom: 32px;
        max-width: 1016px;
        margin-left: auto;
        margin-right: auto;

        ul {
            font-size: 13px;
            color: #525252;
            padding-left: 17px;
            font-weight: 400;

            li {
                margin-bottom: 4px;
            }

            a {
                color: #2680EB;
                text-decoration: none;
                letter-spacing: 0px;
                opacity: 1;
            }
        }

        .landing-onboading-steps-screen-text-column-step1 {
            width: 50%;
            float: left;
            padding-bottom: 0px;
            padding-top: 10px;
            padding-right: 0%;
            padding-left: 39px;

            .prerequisite {
                max-width: 380px;
                padding-right: 10px;
            }

            .step1-text {
                margin: 0px;
                letter-spacing: 0px;
                color: #707070;
                opacity: 1;
                font-size: 20px;
                font-weight: 550;
                margin-bottom: 5px;
            }
        }

        .landing-onboading-steps-screen-image-column-step1 {
            width: 50%;
            float: left;

            .customize-your-sub-image {
                background-image: url(../../image/customize-your-sub.png);
                height: 313px;
                width: 102%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

        }

        .landing-onboading-steps-screen-text-column-step2 {
            width: 50%;
            float: left;
            padding-bottom: 0px;
            margin-top: 0px;
            padding-right: 9%;
            padding-top: 0;
            padding-left: 39px;

            .step2-text {
                margin: 0px;
                letter-spacing: 0px;
                color: #707070;
                opacity: 1;
                font-size: 20px;
                font-weight: 550;
                margin-bottom: 5px;
            }
        }

        .landing-onboading-steps-screen-image-column-step2 {
            width: 50%;
            float: left;
            margin-top: 30px;

            .setup-slack-image {
                background-image: url(../../image/setup-slack.png);
                height: 315px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

            }
        }

        .landing-onboading-steps-screen-text-column-step3 {
            width: 50%;
            float: left;
            padding-bottom: 0px;
            margin-top: 51px;
            padding-right: 9%;
            padding-top: 4%;
            padding-left: 39px;

            .step3-text {
                margin: 0px;
                letter-spacing: 0px;
                color: #707070;
                opacity: 1;
                font-size: 20px;
                font-weight: 550;
                margin-bottom: 5px;
            }
        }

        .landing-onboading-steps-screen-image-column-step3 {
            width: 50%;
            float: left;

            .notify-image {
                background-image: url(../../image/notification.png);
                height: 310px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

            }
        }
    }

    @media only screen and (max-width: 800px) {
        .landing-screen-row {

            .landing-screen-image-column,
            .landing-screen-text-column {
                width: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }

        .on-boarding-header {
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .landing-onboading-steps-screen-row {

            .landing-onboading-steps-screen-text-column-step1,
            .landing-onboading-steps-screen-text-column-step2 {
                float: none;
                width: 100%;
                margin-bottom: 20px;
            }

            .landing-onboading-steps-screen-text-column-step1 {
                .prerequisite {
                    max-width: 100%;
                }
            }

            .landing-onboading-steps-screen-text-column-step3 {
                float: none;
                width: 100%;
                height: 157px;
            }

            .landing-onboading-steps-screen-image-column-step1,
            .landing-onboading-steps-screen-image-column-step2 {
                float: none;
                width: 100%;
                margin-top: 0;

                .customize-your-sub-image {
                    height: 305px;
                    width: 100%;
                    margin-bottom: 15px;
                }

            }

            .landing-onboading-steps-screen-image-column-step3 {
                float: none;
                width: 100%;
                height: 196;
            }
        }

        .add-to-slack {
            background-color: #FFFFFF;
            border: 1px solid #C6CACD;
            border-radius: 5px;
            height: 33px;

            .add-to-slack-text:before {
                content: "";
                width: 23px;
                background-image: url(../../image/slackLogo.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                margin-right: 4px;
                margin-top: -1px;
                height: 20px;
            }

            .add-to-slack-text {
                color: #000000;
                font-size: 13px;
                display: flex;
                justify-content: space-around;
                margin-right: 2px;
            }
        }

    }

    @media only screen and (max-width: 335px) {
        .landing-screen-row {
            .landing-screen-text-column {
                height: 356px;
            }
        }
    }
}

.slack-error-container {
    margin-bottom: 100px;

    .status-stripe.pns-stripe .text {
        width: 0;
    }

    .error-stripe {

        &.static {
            background: #d8e3f1;
            color: #444;
        }

        .grid-container {
            max-width: 977px;

            .banner-link {
                text-align: right;

                .text {
                    color: #444;
                }
            }
        }

    }

    .status-slack-error {
        max-width: 600px;
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;

        .error-image {
            background-image: url(../../image/slackerror.png);
            height: 152px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-bottom: 20px;
        }

        .error-head {
            text-align: center;
            font-size: 21px;
            color: #666666;
            margin-bottom: 15px;

        }

        .error-discription {
            text-align: center;
            color: #666666;
            margin: 0px;
            font-size: 15px;
            padding: 32px;
            font-size: 13.5px;
            background-color: #F4F4FA;
        }

        p {
            text-align: center;
            margin: 0px;

            a {
                color: #2680EB;
                text-decoration: none;
                letter-spacing: 0px;
                opacity: 1;
            }
        }
    }
}

.slack-success-container {
    margin-bottom: 100px;

    .status-stripe.pns-stripe .text {
        width: 0;
    }

    .success-stripe {
        &.static {
            background: #d8e3f1;
        }

        .grid-container {
            max-width: 977px;

            .banner-link {
                text-align: right;

                .text {
                    color: #444;
                }
            }
        }
    }

    .status-slack-success {
        max-width: 711px;
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;

        a {
            color: #2680EB;
            text-decoration: none;
        }

        .success-image {
            background-image: url(../../image/slacksuccess.png);
            height: 152px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-bottom: 20px;
        }

        .success-header {
            text-align: center;
            font-size: 21px;
            color: #666666;
        }

        .success-description-f {
            text-align: center;
            color: #666666;
            box-sizing: border-box;
            padding-left: 9%;
            padding-right: 9%;
            font-size: 13.5px;
        }

        .div1 {
            background-color: #F4F4FA;

            .success-description-s {
                text-align: left;
                color: #666666;
                padding: 32px;
                font-size: 13.5px;

                p {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }


    }

    #disclaimer {
        display: none;
    }
}
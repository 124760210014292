@import "react-date-range/dist/styles.css"; // main css file
@import "react-date-range/dist/theme/default.css"; // theme css file

.filter-panel {
  font-size: 14px;
  margin-top: 18px;
  .filter-title {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
  }

  .clear-all {
    float: right;
    position: relative;
    right: 14%;
    min-width: 50px;
    display: block;
    text-decoration: none;
  }
  .clear-all-mobile {
    display: none;
  }
  .control-styles {
    font-size: 13px;
    margin-bottom: 15px;
    width: 85%;
    label {
      display: none;
      font-size: 13px;
    }
  }
  .date-range {
    position: relative;
    &::before {
      content: "\e245";
      font-family: e-icons, Helvetica, sans-serif;
      position: absolute;
      right: 12px;
      bottom: 8px;
      font-size: 15px;
      color: #ccc;
      z-index: -1;
    }
    input {
      border: 1px solid rgb(204, 204, 204);
      border-radius: 4px;
      height: 38px;
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      background: transparent;
    }
    .picker {
      display: none;
      position: absolute;
      z-index: 100;
      box-shadow: 0 0 5px #ddd;
      background: #fff;

      &.custom {
        width: 430px;
      }

      &.show {
        display: block;
      }

      button.done {
        background: rgb(61, 145, 255);
        border-radius: 3px;
        border: none;
        color: #fff;
        padding: 5px 10px;
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .date-custom-list {
        list-style: none;
        margin: 0;
        padding: 0;
        float: left;

        li {
          padding: 5px 13px;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background-color: #deebff;
          }
          &.selected {
            background-color: #3478dd;
            color: #ffffff;
          }
        }
      }
      .date-range {
        display: none;
        float: right;
        box-shadow: -1px 0px 3px #ededed;
        &.active {
          display: block;
        }
        .btn {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
}

html[lang="ja-jp"] .filter-panel .date-range .picker.custom {
  width: 436px;
}
html[lang="ko-kr"] .filter-panel .date-range .picker.custom {
  width: 432px;
}
html[lang="nb-no"] .filter-panel .date-range .picker.custom {
  width: 438px;
}
html[lang="de-de"] .filter-panel .date-range .picker.custom {
  width: 458px;
}
html[lang="da-dk"] .filter-panel .date-range .picker.custom {
  width: 452px;
}
html[lang="sv-se"] .filter-panel .date-range .picker.custom {
  width: 470px;
}
html[lang="nl-nl"] .filter-panel .date-range .picker.custom {
  width: 472px;
}

html[lang="ru-ru"] .filter-panel .date-range .picker.custom {
  width: 475px;
}

html[lang="cs-cz"],
html[lang="fi-fi"] {
  .filter-panel .date-range .picker.custom {
    width: 464px;
  }
}
html[lang="fr-fr"],
html[lang="pl-pl"] {
  .filter-panel .date-range .picker.custom {
    width: 454px;
  }
}
html[lang="it-it"] .filter-panel .date-range .picker.custom {
  width: 448px;
}
html[lang="es-es"],
html[lang="pt-br"] {
  .filter-panel .date-range .picker.custom {
    width: 446px;
  }
}

@media (max-width: 1023px) {
  .clear-all {
    display: none !important;
  }
  .clear-all-mobile {
    float: right;
    position: relative;
    right: 14%;
    min-width: 50px;
    display: block !important;
    text-decoration: none;
  }
  .filter-panel .clear-all-mobile {
    right: 0;
  }
}

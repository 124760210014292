.collapsible {
  background-color: #fff;
  color: #4b4b4b;
  cursor: pointer;
  padding: 9px 16px 9px 18px;
  text-align: left;
  outline: none;
  font-size: 13px;
  position: relative;
  opacity: 0.9;
  margin-bottom: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-right-width: 40px;
  border-left-width: 4px;
  margin-left: 0;
  margin-right: 0;
  min-height: 47px;

  .link-subscribe {
    text-decoration: none;
    padding: 6px 10px 7px;
    border: 1px solid #a6a6a6;
    border-radius: 28px;
    text-align: center;
    float: right;
    font-size: 14px;
    margin-top: 15px;
    background: none;
    color: #4b4b4b;
    &:hover {
      text-decoration: none;
      background-color: #fafafa;
    }
    &.subscribed {
      border: 1px dashed #228ecc;
      color: #228ecc;
    }
    &.comms {
      margin-top: 10px !important;
    }
  }
  .disabled {
    opacity: 0.8;
    text-transform: initial;
  }
  .link-unsubscribe {
    text-decoration: none;
    padding: 6px 10px 7px;
    border: 1px solid #a6a6a6;
    border-radius: 28px;
    text-align: center;
    float: right;
    font-size: 14px;
    margin-top: 15px;
    background: none;
    color: #4b4b4b;
    &:hover {
      text-decoration: none;
      background-color: #fafafa;
    }
    &.subscribed {
      border: 1px dashed #228ecc;
      color: #228ecc;
    }
    &.comms {
      margin-top: 10px !important;
    }
  }

  .incident-id-container {
    padding: 8px 0;
    position: static;

    .icon-con {
      position: absolute;
      margin: auto;
      top: 34px;
      width: 30px;
      height: 30px;
      left: 10px;
      &.comms {
        top: 25px !important;
      }
    }

    .incident-text-wrap {
      margin-left: 20px;
      margin-right: 5px;
      max-width: 250px;
    }
    .incident-id {
      display: inline;
    }
    .incident-cloud {
      display: block;
    }

    .incident-cloud a {
      color: #228ecc;

      &:hover {
        border-bottom: 1px dotted #228ecc;
      }
    }
  }

  .vertical-center {
    min-height: 54px;
    padding: 6px 0px 4px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      border-right: 1px solid #ccc;
      left: 5px;
      top: 0;
    }
    .inner-wrapper {
      padding-left: 20px;
    }
  }
  &.Global {
    border-color: #ffd300;
  }
  &.Cloud {
    border-color: #ffd300;
  }
  &.Product {
    border-color: #ffd300;
  }
  &.major {
    border-color: #d7373f;
  }
  &.minor {
    border-color: #e68619;
  }
  &.potential,
  &.discovery {
    border-color: grey;
  }
  &.maintenance {
    border-color: #2680eb;
  }

  &.RESOLVED,
  &.EVENT_CANCELED,
  &.DOWNGRADED,
  &.DISMISSED,
  &.Closed,
  &.Resolved,
  &.Completed,
  &.Canceled,
  &.Dismissed {
    border-color: #049e7b !important;

    & + .collapse {
      .status-message {
        border-color: $discovery-color !important;
      }
    }

    .grid-cols-5 {
      width: 66%;
    }
  }

  & + .collapse {
    display: none;
    &,
    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        list-style: none;
      }
    }
  }

  &.active {
    &::before {
      content: "\2212";
    }
  }

  &.active + .collapse {
    display: block;
  }

  &.hide {
    display: none;

    & + .collapse {
      display: none;
    }
  }

  &::before {
    content: "\002B";
    position: absolute;
    font-weight: bold;
    float: right;
    right: -28px !important;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: #fff;
    color: #444;
    text-align: center;
    border-radius: 100%;
    line-height: 15px;
  }

  &.active,
  &:hover {
    opacity: 1;
  }
}

.message-container {
  display: none;
}

.status-message {
  border: 1px solid $discovery-color;
  border-radius: 0.25rem;
  padding: 0.8rem 1.2rem 1rem;
  margin-bottom: 0.8rem;

  .service-name,
  .product-name {
    font-size: 16px;
    padding-bottom: 2px;
    display: inline-block;
  }

  .banner-services {
    margin: 0;
    ul {
      padding: 0;
      margin: 0;
    }
  }

  .login-link {
    color: #228ecc;
    cursor: pointer;
  }
  .impacted-services {
    display: inline-block;
  }
  .impacted-services span:after {
    content: ", ";
  }
  .impacted-services span:last-child:after {
    content: " ";
  }
  &.status-major {
    border-color: $discovery-color;
  }
  &.status-minor {
    border-color: $discovery-color;
  }

  &.status-discovery,
  &.status-potential {
    border-color: $discovery-color;
  }

  .link-event {
    text-align: right;
    button {
      text-align: center;
      color: #4b4b4b;
      text-decoration: none;
      padding: 6px 10px;
      background: none;
      border: 1px solid #a6a6a6;
      border-radius: 28px;
      margin-top: 5px;
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        text-decoration: none;
        background-color: #fafafa;
      }
    }
  }
}

.collapsible.active {
  & + .message-container {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .collapsible {
    .link-subscribe,
    .link-unsubscribe {
      float: left;
      margin-bottom: 10px;
    }
    .col-md-4.incident-id-container {
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;
    }
    .col-md-5.vertical-center {
      padding-top: 10px;
    }
    .vertical-center::before {
      border-right: none;
    }
  }
  .status-message .link-event {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
